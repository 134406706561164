import { Component, OnInit } from '@angular/core';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-more-section',
  templateUrl: './more-section.page.html',
  styleUrls: ['./more-section.page.scss'],
})
export class MoreSectionPage implements OnInit {

  currentLang: string;
  customLocale: any;

  constructor(
    private appRate: AppRate,
    private translate: TranslateService,
  ) {
    this.currentLang = this.translate.currentLang;
    this.setAppRateLanguage();

    this.translate.onLangChange.subscribe((event) => {
      this.currentLang = event.lang;
      this.setAppRateLanguage();
    });
  }

  ngOnInit() {
  }

  setAppRateLanguage() {
    switch (this.currentLang) {
      case 'en-GB': case 'en-US':
      this.customLocale = {
        title: 'Would you mind rating %@?',
        message: 'It won’t take more than a minute and helps to promote our app. Thanks for your support!',
        cancelButtonLabel: 'No, Thanks',
        laterButtonLabel: 'Remind Me Later',
        rateButtonLabel: 'Rate It Now',
        yesButtonLabel: 'Yes!',
        noButtonLabel: 'Not really',
        appRatePromptTitle: 'Do you like using %@?',
        feedbackPromptTitle: 'Mind giving us some feedback?',
      };
      break;
      case 'fr-FR':
      this.customLocale = {
        title: 'Tu veux bien donner ton avis sur cette application ?',
        message: 'Ça ne prendra pas plus d’une minute et ça peut permettre de faire connaître notre application. Merci de ton soutien !',
        cancelButtonLabel: 'Non, merci',
        laterButtonLabel: 'Me le rappeler plus tard',
        rateButtonLabel: 'Note cette application',
        yesButtonLabel: 'Oui!',
        noButtonLabel: 'Pas vraiment',
        appRatePromptTitle: 'Tu aimes cette application?',
        feedbackPromptTitle: 'Peux-tu nous dire pourquoi ?',
      };
      break;
      case 'nl-NL':
      this.customLocale = {
        title: 'Beoordeel %@',
        message: 'Als het gebruik van %@ je bevalt, wil je dan een moment nemen om het te beoordelen? Het duurt nog geen minuut. Bedankt voor je steun!',
        cancelButtonLabel: 'Nee, bedankt',
        laterButtonLabel: 'Herinner me er later aan',
        rateButtonLabel: 'Beoordeel nu',
        yesButtonLabel: 'Ja',
        noButtonLabel: 'Nee',
        appRatePromptTitle: 'Vind je het fijn om de %@ app te gebruiken?',
        feedbackPromptTitle: 'Zou je ons feedback willen geven?',
      };
      break;
    }
  }

  promptAppRate() {

    this.appRate.preferences = {
      inAppReview: true,
      displayAppName: 'Sitting Still',
      storeAppURL: {
        ios: 'sittingstilling',
        android: 'market://details?id=packagename',
      },
      customLocale: this.customLocale,
    };
    this.appRate.promptForRating(true);
  }

}
