import { SocialShareService } from '../social/social.share.service';
import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { MediaMetaData } from './models/media-meta-data';
import { MediaStore } from './stores/media-store';

@Component({
  selector: 'app-meditate',
  templateUrl: './meditate.page.html',
  styleUrls: ['./meditate.page.scss'],
})
export class MeditatePage implements OnInit {
  media: MediaMetaData[];
  constructor(private navCtrl: NavController, private mediaStore: MediaStore, public socialShareService: SocialShareService) {
    this.mediaStore.get().subscribe(
      (media: MediaMetaData[]) => {
        this.media = media;
      },
    );
  }

  ngOnInit() {
  }

  openMeditateScreen() {
    this.navCtrl.navigateForward(['/meditate-screen', { mediaId: 1}]);
  }

}
